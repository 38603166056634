import TakipXmlParser from "./TakipXmlParser";
import {DosyaFormDataInterface} from "./DosyaFormDataInterface";
import {MtsDosyaFormData} from "./MtsDosyaFormData";
import {MtsXmlParser} from "./MtsXmlParser";

export default class XmlController {
    static async parse(xml: string,formData: DosyaFormDataInterface): Promise<TakipXmlParser[]> {
        try{
            let takipler: TakipXmlParser[] = [];
            let elem = document.createElement("container");
            elem.innerHTML = xml;
            let dosyaNodeList = elem.querySelectorAll("dosya");
            for (const dosya of dosyaNodeList) {
                let takip = await TakipXmlParser.parse(dosya,formData);
                takipler.push(takip);
            }
            return Promise.resolve(takipler);
        }catch (e) {
            return Promise.reject(e);
        }
    }
    static async readXmlWithEncoded(file: Blob): Promise<any>{
        return new Promise((resolve,reject)=>{
            try{
                let encodingControl = false;
                if (file.type == "text/xml" || file.type == "application/xml") {
                    let reader = new FileReader();
                    reader.readAsText(file);
                    reader.onloadend =  () => {
                        let xmlData: any = reader.result;
                        if (!xmlData.trim().startsWith('<?xml')) {
                            console.log("XML bildirim satırı ekleniyor");
                            xmlData = '<?xml version="1.0" encoding="UTF-8"?>\n' + xmlData;
                        }
                        
                        if(encodingControl == false){
                            if (xmlData.indexOf('encoding') >= 0) {
                                let encoding = xmlData.split('encoding="')[1].split('"')[0];
                                encodingControl = true;
                                console.log("encoding",encoding,encodingControl);
                                reader.readAsText(file, encoding);
                            } else {
                                // Encoding bilgisi yoksa direkt olarak resolve et
                                return resolve(xmlData);
                            }
                        }else{
                            return resolve(xmlData);
                        }
                    }
                } else {
                    alert("Sadece XML Dosyaları Seçilebilir.");
                    return  reject({message:"Sadece XML Dosyaları Seçilebilir."})
                }
            }catch (e) {
                return  reject(e);
            }
        })

    }
    static async mtsParse(xml: string,formData: MtsDosyaFormData): Promise<MtsXmlParser[]> {
        try{
            let takipler: MtsXmlParser[] = [];
            let elem = document.createElement("container");
            elem.innerHTML = xml;
            let dosyaNodeList = elem.querySelectorAll("dosya");
            for (const dosya of dosyaNodeList) {
                //@ts-ignore
                let takip = await MtsXmlParser.parse(dosya,formData);
                takipler.push(takip);
            }
            return Promise.resolve(takipler);
        }catch (e) {
            return Promise.reject(e);
        }
    }
}